// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2019-js": () => import("./../../../src/pages/2019.js" /* webpackChunkName: "component---src-pages-2019-js" */),
  "component---src-pages-2020-js": () => import("./../../../src/pages/2020.js" /* webpackChunkName: "component---src-pages-2020-js" */),
  "component---src-pages-2021-js": () => import("./../../../src/pages/2021.js" /* webpackChunkName: "component---src-pages-2021-js" */),
  "component---src-pages-2022-js": () => import("./../../../src/pages/2022.js" /* webpackChunkName: "component---src-pages-2022-js" */),
  "component---src-pages-21-patterns-js": () => import("./../../../src/pages/21-patterns.js" /* webpackChunkName: "component---src-pages-21-patterns-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

